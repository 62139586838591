import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Skeleton, Button } from 'antd'
import './portal.css';
import Instruction from './instruction';
import TestBoard from './testBoard';
import Answer from '../answersheet/answer';
import { fetchTraineedata, setTestDetsils, fetchTestdata } from '../../../actions/traineeAction';
import queryString from 'query-string';
import main from './main.png';
const { Title } = Typography;


class MainPortal extends Component {
    constructor(props) {
        super(props);
        let params = queryString.parse(this.props.location.search);
        this.state = {
            testDetails: params
        }
        this.props.setTestDetsils(params.testid, params.traineeid)

    }

    componentDidMount() {
        console.log(this.props.trainee.traineeid)
        this.props.fetchTraineedata(this.state.testDetails.traineeid)
        this.props.fetchTestdata(this.state.testDetails.testid, this.state.testDetails.traineeid)
    }


    render() {
        if (this.props.trainee.initialloading2 || this.props.trainee.initialloading1) {
            return (
                <div className="skeletor-wrapper">
                    <Skeleton active />
                    <Skeleton active />
                </div>
            )
        }
        else {
            if (this.props.trainee.invalidUrl) {
                return window.location.href = ``;
            }
            else {
                if (this.props.trainee.LocaltestDone) {
                    return (
                        <div className="skeletor-wrapper">
                            <Answer />
                        </div>
                    )
                }
                else {
                    if (this.props.trainee.testconducted) {
                        return (
                            <div className='parallax'>
                                <div className="Test-portal-not-started-yet-wrapper">
                                    <div className="Test-portal-not-started-yet-inner">
                                        <center>
                                            <h4>
                                                <img src={main} style={{ width: '50px', height: '50px' }} alt="Novisync" />
                                                Novisync Exam</h4>
                                        </center>
                                        <Title className="Test-portal-not-started-yet-inner-message" style={{ color: '#fff' }} level={4}>The Test is Over!<br /> You are late.</Title>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    else {
                        if (!this.props.trainee.testbegins) {
                            return (
                                <div className="Test-portal-not-started-yet-wrapper">
                                    <div className="Test-portal-not-started-yet-inner">
                                        <center>                                        <h4>
                                            <img src={main} style={{ width: '50px', height: '50px' }} alt="Novisync" />
                                            Novisync Exam</h4>
                                        </center>
                                        <Title className="Test-portal-not-started-yet-inner-message" style={{ color: '#fff' }} level={4}>The test has not started yet. Wait for the proctor's instruction then refresh the page.</Title>
                                        <center>
                                            <Button type="primary" onClick={() => window.location.reload()}>Refresh</Button>
                                        </center>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            if (this.props.trainee.startedWriting) {
                                return (
                                    <div>
                                        <TestBoard />
                                    </div>
                                )
                            }
                            else {
                                return (
                                    <div>
                                        <Instruction />
                                    </div>
                                )
                            }
                        }
                    }
                }
            }

        }

    }

}


const mapStateToProps = state => ({
    trainee: state.trainee
});




export default connect(mapStateToProps, {
    fetchTraineedata,
    setTestDetsils,
    fetchTestdata
})(MainPortal);

