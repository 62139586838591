import React from 'react';
import { Table, Tag, Card, Input, Icon, message, Tooltip } from 'antd';
import CopyToClipboard from 'react-copy-to-clipboard';





export default function Trainee(props) {
    let maxMarks=props.maxmMarks || 2;
    const columns = [
        {
            title: 'Name',
            dataIndex: 'userid.name',
            key: 'userid.name'
        },
        {
            title: 'Email Id',
            dataIndex: 'userid.emailid',
            key: 'userid.emailid',
        },
        {
            title: 'Contact No',
            dataIndex: 'userid.contact',
            key: 'userid.contact',
        },
        {
            title: 'Organisation',
            dataIndex: 'userid.organisation',
            key: 'userid.organisation',
        },
        {
            title:'Score',
            dataIndex: 'score',
            key: 'score',
        },
        {
            title:'Status',
            dataIndex:'score',
            key: '_id',
            render: tag => (
                <span>
                    <Tag color={tag >= maxMarks/2 ? 'green' : 'red'} key={tag}>
                        {tag >= maxMarks/2 ? 'Pass' : 'Fail'}
                    </Tag>
                </span>
            )
        },
        {
            title: 'Links',
            key: 'userid._id',
            dataIndex: 'userid._id',
            render: user_id => ( 
              <Input disabled={true} value={`${props.mainLink}/trainee/taketest?testid=${props.id}&traineeid=${user_id}`} addonAfter={<Icon type="link" onClick={() => window.open(`${props.mainLink}trainee/taketest?testid=${props.id}&traineeid=${user_id}`)}/>}
              suffix={
                <Tooltip title="Copy to clipboard">
                   <CopyToClipboard text={`${props.mainLink}/trainee/taketest?testid=${props.id}&traineeid=${user_id}`} onCopy={()=>message.success('Link Copied to clipboard')}><Icon type="copy"/></CopyToClipboard>
                </Tooltip>
              }
              />
            ),
        }
    ];
    return (
        <div>
            <Card>
                <div className="download-section">
                    <Table pagination={false} rowKey="_id" columns={columns} dataSource={props.stats}/>
                </div>
            </Card>
        </div>
    )
}
