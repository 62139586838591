import React, { useState, useEffect } from 'react'
import { Get } from '../../../services/axiosCall'
import apis from '../../../services/Apis';
import main from './novisync.png';

export default function Landingpage() {

    const [testLinks, setTestLinks] = useState([])

    const getAlltestLinks = () => {
        Get({
            url: `${apis.GET_ALL_TESTS_LINKS}`,
        }).then((response) => {
            setTestLinks(response.data.data);
        })
    }

    useEffect(() => {
        getAlltestLinks()
    }, [])


    return (
        <div>
            <div className="parallax">
                <div className="reasendmail-container-register">
                    <center>
                        <img src={main} with="80" height="80" />
                        <h3>Novisync Exam</h3>
                    </center>
                    {
                        testLinks.length > 0 ?
                            <div>
                                <label style={{ color: 'black', fontSize: '18px' }}>Current Test</label>
                                <br/>
                                <br/>
                                {testLinks.map(x => {
                                    return <p key={x._id}><a style={{ color: 'black', fontSize: '16px', marginBottom: '10px', textDecoration: 'underline' }} href={`${apis.BASE_LOCAL_URL}/trainee/register?testid=${x._id}`}>{x.title}</a></p>
                                })}
                            </div>
                            :
                            <p style={{ color: 'black', fontSize: '18px' }}>Currently No Tests Available</p>
                    }
                    <div style={{ marginTop: '40px' }}>
                        <a href='/login' style={{ color: 'blue', fontSize: '10px' }}>Internal Login</a>
                    </div>
                </div>
            </div>
        </div>
    )
}