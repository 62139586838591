export const steps = [
    {
      title: 'Basic details'
    },
    {
      title: 'Select Questions'
    },
    {
      title: 'Finalize'
    }
];